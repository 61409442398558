.box {
    transition: transform .5s
}

.box:hover {
    box-shadow: 0 0 20px rgba(7, 7, 7, 0.2); 
    transition: opacity 0.5s ease;
    transform: scale(1.05)
}

#projectImg {
    object-fit: scale-down;
    max-width: 200px;
    height: 180px;
}

@media only screen and (max-width: 1000px) {
    .boxProj {
      display:inline-flex;
      width: 100%;
    }

    .box {
        font-size: 16px;
    }
}

@media only screen and (max-width: 430px) {
    .box {
        font-size: 11px;
    }

    .desc {
        font-size: 11px;
        max-width: 150px;
    }

    #projectImg {
        object-fit: scale-down;
        max-width: 100px;
        height: 180px;
    }

    .caption {
        max-width: 140px;
    }

    .caption-hr {
        max-width: 140px;
    }

    .caption-badge {
        max-width: 90px;
    }
}