ul{
    list-style-position: inside;  
}
  
img:not(.logoImg) :hover{
    box-shadow: 0 0 20px rgba(7, 7, 7, 0.2); 
}

.contactButton:hover{
    box-shadow: 0 0 20px rgba(7, 7, 7, 0.2); 
}

#page p{
    margin: 0px 60px 0px 60px;
}

#page{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}

.line-1{
    top: 50%;  
    width: 12.5em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

.footer {
    padding: 0 0 10px 0;
    background-color:#0055DD;
    margin-top: auto;
    color:#F1FAFF;
    text-align: center;
}

/* Animation */
.anim-typewriter{
    animation: typewriter 4s steps(50) 1s 1 normal both,
                blinkTextCursor 750ms steps(50) infinite normal;
}
@keyframes typewriter{
    from{width: 0;}
    to{width: 12.5em;}
}
@keyframes blinkTextCursor{
    from{border-right-color: #1d305abf; height:25px}
    to{border-right-color: transparent; height:25px}
}

/* MTG Styles */
@media only screen and (max-width: 600px) {
    #playerTable {
        font-size: 16px;
    }
}

@media only screen and (max-width: 350px) {
    #playerTable {
        font-size: 12px;
    }
}